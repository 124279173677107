import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import logolight from '../../images/logo-light.webp';
import logodark from '../../images/logo-dark.webp';
import {
    Navbar,
    NavItem,
    Nav,
    NavLink
} from 'reactstrap'

const Topbar = () => {
    const [isCollapsed, setIsCollapsed] = useState(true)

    const handleScroll = () => {
        var scrollY = window.scrollY;
        if (scrollY >= 50) {
            document.getElementById("sticky1").classList.add("nav-sticky");
        }
        else {
            document.getElementById("sticky1").classList.remove("nav-sticky");
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, []);

    return (
        <Fragment>
            <nav id="sticky1" className="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark nav-wrapper">
                <div className="container">
                    <Link className="navbar-brand logo text-uppercase" to="/">
                        <img src={logolight} className="logo-light img-fluid" alt="" />
                        <img src={logodark} className="logo-dark img-fluid" alt="" />
                    </Link>
                    <button onClick={() => setIsCollapsed(prevState => !prevState)} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                        <i className="mdi mdi-menu"></i>
                    </button>
                    <div className={ isCollapsed ? "collapse navbar-collapse" : "collapse navbar-collapse show" } id="navbarCollapse">

                        <Navbar>
                            <Nav className="me-auto" navbar>
                                <NavItem>
                                    <NavLink href="/">Home</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="/props">
                                        Props (Beta)
                                    </NavLink>
                                </NavItem>
                                <NavItem className="join-server-button">
                                    <NavLink target="_blank" href="https://discord.gg/eFngWcSKg7">
                                        Join Server
                                    </NavLink>
                                </NavItem>
                              {/*  <NavItem className="donate-button">
                                    <NavLink target="_blank" href="https://www.buymeacoffee.com/zhaux">
                                        Donate
                                    </NavLink>
                                </NavItem>*/}
                            </Nav>
                        </Navbar>
                    </div>
                    


                </div>
            </nav>

        </Fragment>
    );
}

export default Topbar;