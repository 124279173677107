import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import App from './App';
import { BrowserRouter } from 'react-router-dom';

const tagManagerArgs = {
  gtmId: 'GTM-TNNT6F9'
}

TagManager.initialize(tagManagerArgs)

const app = (
    <BrowserRouter>
        <App />
    </BrowserRouter>
);

ReactDOM.render(app, document.getElementById('root'));