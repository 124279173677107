import React, { Fragment, useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import Table from '../../components/Props/Table';
import GlobalFilters from '../../components/Props/GlobalFilters';
import FaqModal from '../../components/Props/FaqModal';

const Props = () => {

    return (
        <Fragment>
            <section className="bg-props">
                <div className="bg-overlay"></div>
                <div className="home-center">
                    <div className="home-desc-center">
                        <div className="container">
                            <Row className="justify-content-center">
                                <Col lg="10">
                                    <div className="home-content text-center">
                                        <p className="home-desc line-height_1_8 mt-4 f-20">
                                            ZhauxBot is discontinued until further notice. Join the Discord server for more information.
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    );
}

export default Props;   
