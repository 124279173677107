import React, { Fragment, useMemo, useEffect, useState, useRef } from 'react';
import { TailSpin } from 'react-loader-spinner'
import * as d3 from "d3";
import { 
    Table, 
    Row, 
    Col, 
    Button, 
    Input, 
    CustomInput
} from 'reactstrap';
import { Filter, DefaultColumnFilter, InputColumnFilter, NumberColumnFilter } from './Filters';

import {
    useTable,
    useSortBy,
    useFilters,
    useExpanded,
    usePagination,
} from 'react-table';

const color = d3.scaleLinear()
    .domain([50, 70])
    .range(["#FFD895", "#17C200"]);

const emptyColor = '#E6E6E6';
const emptyTextColor = '#959595'

const processOdds = (odds, ppLine, udLine) => {
    if (odds) {
        let lean;
        let leanFairOdds = 50;
        let leanAmerOdds;
        let ppLineDiff;
        let udLineDiff;
        let ppCorrelation;
        let udCorrelation;

        if (odds['overFairOdds'] > odds['underFairOdds']) {
            lean = 'Over ⬆️'
            leanFairOdds = odds['overFairOdds']
            leanAmerOdds = odds['overOdds']
        } else if (odds['overFairOdds'] < odds['underFairOdds']) {
            lean = 'Under ⬇️'
            leanFairOdds = odds['underFairOdds']
            leanAmerOdds = odds['underOdds']
        } else {
            leanAmerOdds = odds['overOdds']
        }

        if (ppLine !== null) {
            ppLineDiff = odds['lineScore'] - ppLine

            if (ppLineDiff < 0 && lean == 'Over ⬆️') {
                ppCorrelation = 'negative'
            } else if (ppLineDiff > 0 && lean == 'Under ⬇️') {
                ppCorrelation = 'negative'
            } else if (ppLineDiff < 0 && lean == 'Under ⬇️') {
                ppCorrelation = 'positive'
            } else if (ppLineDiff > 0 && lean == 'Over ⬆️') {
                ppCorrelation = 'positive'
            }
        }

        if (udLine !== null) {
            udLineDiff = odds['lineScore'] - udLine

            if (udLineDiff < 0 && lean == 'Over ⬆️') {
                udCorrelation = 'negative'
            } else if (udLineDiff > 0 && lean == 'Under ⬇️') {
                udCorrelation = 'negative'
            } else if (udLineDiff < 0 && lean == 'Under ⬇️') {
                udCorrelation = 'positive'
            } else if (udLineDiff > 0 && lean == 'Over ⬆️') {
                udCorrelation = 'positive'
            }
        }

        return {
            lineScore: odds['lineScore'],
            ppLineDiff: ppLineDiff > 0 ? `+${ppLineDiff}` : ppLineDiff,
            udLineDiff: udLineDiff > 0 ? `+${udLineDiff}` : udLineDiff,
            lean: lean,
            fairOdds: leanFairOdds,
            amerOdds: leanAmerOdds,
            ppCorrelation: ppCorrelation,
            udCorrelation: udCorrelation,
        }
    } else {
        return null;
    }
}

const processData = (data, negativeCorrelationFilter) => {
    return data.map(d => {
        return ({
            ...d,
            draftkingsOdds: processOdds(d['draftkingsOdds'], d['ppLine'], d['udLine']),
            fanduelOdds: processOdds(d['fanduelOdds'], d['ppLine'], d['udLine']),
            pinnacleOdds: processOdds(d['pinnacleOdds'], d['ppLine'], d['udLine']),
            caesarsOdds: processOdds(d['caesarsOdds'], d['ppLine'], d['udLine']),
            negativeCorrelationFilter: negativeCorrelationFilter
        })
    })
}

const checkPositiveCorrelation = (appFilter, row) => {
    if (row == null) {
        return false
    }

    if (appFilter.includes('PrizePicks') && !(appFilter.includes('Underdog')) && row['ppCorrelation'] == 'positive') {
        return true
    } else if (appFilter.includes('Underdog') && !(appFilter.includes('PrizePicks')) && row['udCorrelation'] == 'positive') {
        return true
    }

    return false
}

const checkNegativeCorrelation = (appFilter, row) => {
    if (row == null) {
        return true
    }

    if (appFilter.includes('PrizePicks') && !(appFilter.includes('Underdog')) && row['ppCorrelation'] == 'negative') {
        return true
    } else if (appFilter.includes('Underdog') && !(appFilter.includes('PrizePicks')) && row['udCorrelation'] == 'negative') {
        return true
    }

    return false
}

const TableComponent = ({ 
    data, sportsbookFilter, leagueFilter, appFilter, positiveCorrelationFilter, 
    negativeCorrelationFilter, americanOddsFilter, tableLoading 
    }) => {
    const [tableData, setTableData] = useState([])
    const [hiddenColumns, setHiddenColumns] = useState([])

    const leagueFilterRef = useRef();
    const playerNameFilterRef = useRef();
    const propFilterRef = useRef();

    useEffect(() => {
        if (leagueFilterRef.current) leagueFilterRef.current.clearValue()
        if (playerNameFilterRef.current) playerNameFilterRef.current.clearValue()
        if (propFilterRef.current) propFilterRef.current.clearValue()

        const processedData = processData(data, negativeCorrelationFilter)

        setTableData(processedData.filter(d => {
            if (appFilter.includes('PrizePicks') && !(appFilter.includes('Underdog')) && d['ppLine'] == null) {
                return false
            }

            if (appFilter.includes('Underdog') && !(appFilter.includes('PrizePicks')) && d['udLine'] == null) {
                return false
            }

            // RETURN FALSE = FILTER IT OUT
            if (positiveCorrelationFilter) {
                let fanduelPositiveCorrelation = false
                let draftkingsPositiveCorrelation = false
                let pinnaclePositiveCorrelation = false
                let caesarsPositiveCorrelation = false

                if (sportsbookFilter.includes('FanDuel')) {
                    fanduelPositiveCorrelation = checkPositiveCorrelation(appFilter, d['fanduelOdds'])
                }
                if (sportsbookFilter.includes('DraftKings')) {
                    draftkingsPositiveCorrelation = checkPositiveCorrelation(appFilter, d['draftkingsOdds'])
                }
                if (sportsbookFilter.includes('Pinnacle')) {
                    pinnaclePositiveCorrelation = checkPositiveCorrelation(appFilter, d['pinnacleOdds'])
                }
                if (sportsbookFilter.includes('Caesars')) {
                    caesarsPositiveCorrelation = checkPositiveCorrelation(appFilter, d['caesarsOdds'])
                }

                if (!fanduelPositiveCorrelation && !draftkingsPositiveCorrelation && !pinnaclePositiveCorrelation && !caesarsPositiveCorrelation) return false
            } else if (negativeCorrelationFilter) {
                let fanduelNegativeCorrelation = true
                let draftkingsNegativeCorrelation = true
                let pinnacleNegativeCorrelation = true
                let caesarsNegativeCorrelation = true

                if (sportsbookFilter.includes('FanDuel')) {
                    fanduelNegativeCorrelation = checkNegativeCorrelation(appFilter, d['fanduelOdds'])
                }
                if (sportsbookFilter.includes('DraftKings')) {
                    draftkingsNegativeCorrelation = checkNegativeCorrelation(appFilter, d['draftkingsOdds'])
                }
                if (sportsbookFilter.includes('Pinnacle')) {
                    pinnacleNegativeCorrelation = checkNegativeCorrelation(appFilter, d['pinnacleOdds'])
                }
                if (sportsbookFilter.includes('Caesars')) {
                    caesarsNegativeCorrelation = checkNegativeCorrelation(appFilter, d['caesarsOdds'])
                }

                if (fanduelNegativeCorrelation && draftkingsNegativeCorrelation && pinnacleNegativeCorrelation && caesarsNegativeCorrelation) return false
            }

            return leagueFilter.includes(d['leagueName'])
        }));
    }, [data, leagueFilter, appFilter, positiveCorrelationFilter, negativeCorrelationFilter, sportsbookFilter, appFilter]);

    useEffect(() => {
        let tempHiddenColumns = []

        if (!(sportsbookFilter.includes('FanDuel'))) {
            tempHiddenColumns.push('fanduelOdds.lineScore')
            tempHiddenColumns.push('fanduelOdds.ppLineDiff')
            tempHiddenColumns.push('fanduelOdds.udLineDiff')
            tempHiddenColumns.push('fanduelOdds.lean')
            tempHiddenColumns.push('fanduelOdds.fairOdds')
        }

        if (!(sportsbookFilter.includes('DraftKings'))) {
            tempHiddenColumns.push('draftkingsOdds.lineScore')
            tempHiddenColumns.push('draftkingsOdds.ppLineDiff')
            tempHiddenColumns.push('draftkingsOdds.udLineDiff')
            tempHiddenColumns.push('draftkingsOdds.lean')
            tempHiddenColumns.push('draftkingsOdds.fairOdds')
        }

        if (!(sportsbookFilter.includes('Pinnacle'))) {
            tempHiddenColumns.push('pinnacleOdds.lineScore')
            tempHiddenColumns.push('pinnacleOdds.ppLineDiff')
            tempHiddenColumns.push('pinnacleOdds.udLineDiff')
            tempHiddenColumns.push('pinnacleOdds.lean')
            tempHiddenColumns.push('pinnacleOdds.fairOdds')
        }

        if (!(sportsbookFilter.includes('Caesars'))) {
            tempHiddenColumns.push('caesarsOdds.lineScore')
            tempHiddenColumns.push('caesarsOdds.ppLineDiff')
            tempHiddenColumns.push('caesarsOdds.udLineDiff')
            tempHiddenColumns.push('caesarsOdds.lean')
            tempHiddenColumns.push('caesarsOdds.fairOdds')
        }

        if (!(appFilter.includes('PrizePicks'))) {
            tempHiddenColumns.push('ppLine')
            tempHiddenColumns.push('fanduelOdds.ppLineDiff')
            tempHiddenColumns.push('draftkingsOdds.ppLineDiff')
            tempHiddenColumns.push('pinnacleOdds.ppLineDiff')
            tempHiddenColumns.push('caesarsOdds.ppLineDiff')
        } 

        if (!(appFilter.includes('Underdog'))) {
            tempHiddenColumns.push('udLine')
            tempHiddenColumns.push('fanduelOdds.udLineDiff')
            tempHiddenColumns.push('draftkingsOdds.udLineDiff')
            tempHiddenColumns.push('pinnacleOdds.udLineDiff')
            tempHiddenColumns.push('caesarsOdds.udLineDiff')
        }

        setHiddenColumns([...tempHiddenColumns])
    }, [sportsbookFilter, appFilter, americanOddsFilter])

    const minFilter = (rows, columnIds, filterValue) => {
        const columnId = columnIds[0]
        const sportsbook = columnId.split('.')[0]
        const columnName = columnId.split('.')[1]


        return rows.filter(row => {
            if (filterValue === '') return true

            return row.original[sportsbook] ? row.original[sportsbook][columnName] >= filterValue : false
        })
    }

    const columns = useMemo(
        () => [
            {
                Header: 'League',
                accessor: 'leagueName',
                disableSortBy: true,
                Filter: InputColumnFilter,
                ref: leagueFilterRef,
                filter: 'equals',
                id: 'league',
                name: 'League',
                Cell: ({ cell }) => {
                    const { value } = cell;

                    return (
                        <div className="prop-table-cell__div">
                            {value}
                        </div>
                    );
                }
            },
            {
                Header: 'Player',
                accessor: 'playerName',
                Filter: InputColumnFilter,
                ref: playerNameFilterRef,
                filter: 'equals',
                id: 'playerName',
                Cell: ({ cell }) => {
                    const { value } = cell;

                    return (
                        <div className="prop-table-cell__div">
                            {value}
                        </div>
                    );
                }
            },
            {
                Header: 'Prop',
                accessor: 'statType',
                Filter: InputColumnFilter,
                ref: propFilterRef,
                filter: 'equals',
                id: 'statType',
                Cell: ({ cell }) => {
                    const { value } = cell;

                    return (
                        <div className="prop-table-cell__div">
                            {value}
                        </div>
                    );
                }
            },
            {
                Header: 'PrizePicks Line',
                disableSortBy: true,
                accessor: 'ppLine',
                Cell: ({ cell }) => {
                    const { value } = cell;

                    let backgroundColor = emptyColor;
                    if (value) {
                        backgroundColor = 'white'
                    }

                    return (
                        <div className="prop-table-cell__div" style={{ background: backgroundColor }}>
                            {value}
                        </div>
                    );
                }
            },
            {
                Header: 'Underdog Line',
                disableSortBy: true,
                accessor: 'udLine',
                Cell: ({ cell }) => {
                    const { value } = cell;

                    let backgroundColor = emptyColor;
                    if (value) {
                        backgroundColor = 'white'
                    }

                    return (
                        <div className="prop-table-cell__div" style={{ background: backgroundColor }}>
                            {value}
                        </div>
                    );
                }
            },
            {
                id: 'fanduel',
                Header: 'FanDuel',
                columns: [
                    {
                        Header: 'Line',
                        accessor: 'fanduelOdds.lineScore',
                        disableSortBy: true,
                        Cell: ({ cell, row }) => {
                            const { value } = cell;

                            const isNegativeCorrelation = checkNegativeCorrelation(appFilter, cell['row']['original']['fanduelOdds']);
                            
                            let backgroundColor = emptyColor;
                            let textColor = emptyTextColor;

                            if (cell['row']['original']['negativeCorrelationFilter']) {
                                if (!isNegativeCorrelation && value) {
                                    backgroundColor = 'white'
                                    textColor = 'black'
                                }
                            } else {
                                if (value) {
                                    backgroundColor = 'white'
                                    textColor = 'black'
                                }
                            }
                            

                            return (
                                <div className="prop-table-cell__div" style={{ background: backgroundColor, color: textColor }}>
                                    {value}
                                </div>
                            );
                        }
                    },
                    {
                        Header: 'PP Line Diff',
                        accessor: 'fanduelOdds.ppLineDiff',
                        disableSortBy: true,
                        Cell: ({ cell, row }) => {
                            const { value } = cell;

                            const isNegativeCorrelation = checkNegativeCorrelation(appFilter, cell['row']['original']['fanduelOdds']);

                            let backgroundColor = emptyColor;
                            let textColor = emptyTextColor;

                            if (cell['row']['original']['negativeCorrelationFilter']) {
                                if (!isNegativeCorrelation && value) {
                                    backgroundColor = 'white'
                                    textColor = 'black'
                                }
                            } else {
                                if (value) {
                                    backgroundColor = 'white'
                                    textColor = 'black'
                                }
                            }

                            return (
                                <div className="prop-table-cell__div" style={{ background: backgroundColor, color: textColor }}>
                                    {value !== null && value !== 0 && value}
                                </div>
                            );
                        }
                    },
                    {
                        Header: 'UD Line Diff',
                        accessor: 'fanduelOdds.udLineDiff',
                        disableSortBy: true,
                        Cell: ({ cell, row }) => {
                            const { value } = cell;

                            const isNegativeCorrelation = checkNegativeCorrelation(appFilter, cell['row']['original']['fanduelOdds']);

                            let backgroundColor = emptyColor;
                            let textColor = emptyTextColor;

                            if (cell['row']['original']['negativeCorrelationFilter']) {
                                if (!isNegativeCorrelation && value) {
                                    backgroundColor = 'white'
                                    textColor = 'black'
                                }
                            } else {
                                if (value) {
                                    backgroundColor = 'white'
                                    textColor = 'black'
                                }
                            }
                            return (
                                <div className="prop-table-cell__div" style={{ background: backgroundColor, color: textColor }}>
                                    {value !== null && value !== 0 && value}
                                </div>
                            );
                        }
                    },
                    {
                        Header: 'Lean',
                        accessor: 'fanduelOdds.lean',
                        disableSortBy: true,
                        Cell: ({ cell }) => {
                            const { value } = cell;

                            const isNegativeCorrelation = checkNegativeCorrelation(appFilter, cell['row']['original']['fanduelOdds']);

                            let backgroundColor = emptyColor
                            let textColor = emptyTextColor;

                            if (cell['row']['original']['negativeCorrelationFilter']) {
                                if (!isNegativeCorrelation && value) {
                                    backgroundColor = 'white'
                                    textColor = 'black'
                                }
                            } else {
                                if (value) {
                                    backgroundColor = 'white'
                                    textColor = 'black'
                                }
                            }

                            return (
                                <div className="prop-table-cell__div" style={{ background: backgroundColor, color: textColor }}>
                                    {value}
                                </div>
                            );
                        }
                    },
                    {
                        Header: 'Fair Odds (%)',
                        accessor: 'fanduelOdds.fairOdds',
                        Filter: NumberColumnFilter,
                        filter: minFilter,
                        Cell: ({ cell }) => {
                            const { value } = cell;

                            let amerOdds;
                            if (cell['row']['original']['fanduelOdds']) {
                                amerOdds = cell['row']['original']['fanduelOdds']['amerOdds'];
                            }

                            const isNegativeCorrelation = checkNegativeCorrelation(appFilter, cell['row']['original']['fanduelOdds']);

                            let backgroundColor = emptyColor
                            let textColor = emptyTextColor;

                            if (cell['row']['original']['negativeCorrelationFilter']) {
                                if (!isNegativeCorrelation && value) {
                                    backgroundColor = color(value)
                                    textColor = 'black'
                                }
                            } else {
                                if (value) {
                                    backgroundColor = color(value)
                                    textColor = 'black'
                                }
                            }

                            return (
                                <div className="prop-table-cell__div" style={{ background: backgroundColor, color: textColor }}>
                                    {
                                        value && 
                                            <Fragment>
                                                <span>{value.toFixed(2)}</span>
                                                {
                                                    amerOdds && americanOddsFilter &&
                                                        <span>({amerOdds})</span>
                                                }
                                            </Fragment>
                                    }
                                </div>
                            );
                        }
                    },
                ]
            },
            {
                Header: 'DraftKings',
                columns: [
                    {
                        Header: 'Line',
                        accessor: 'draftkingsOdds.lineScore',
                        disableSortBy: true,
                        Cell: ({ cell, row }) => {
                            const { value } = cell;

                            const isNegativeCorrelation = checkNegativeCorrelation(appFilter, cell['row']['original']['draftkingsOdds']);

                            let backgroundColor = emptyColor;
                            let textColor = emptyTextColor;

                            if (cell['row']['original']['negativeCorrelationFilter']) {
                                if (!isNegativeCorrelation && value) {
                                    backgroundColor = 'white'
                                    textColor = 'black'
                                }
                            } else {
                                if (value) {
                                    backgroundColor = 'white'
                                    textColor = 'black'
                                }
                            }

                            return (
                                <div className="prop-table-cell__div" style={{ background: backgroundColor, color: textColor }}>
                                    {value}
                                </div>
                            );
                        }
                    },
                    {
                        Header: 'PP Line Diff',
                        accessor: 'draftkingsOdds.ppLineDiff',
                        disableSortBy: true,
                        Cell: ({ cell, row }) => {
                            const { value } = cell;

                            const isNegativeCorrelation = checkNegativeCorrelation(appFilter, cell['row']['original']['draftkingsOdds']);

                            let backgroundColor = emptyColor;
                            let textColor = emptyTextColor;

                            if (cell['row']['original']['negativeCorrelationFilter']) {
                                if (!isNegativeCorrelation && value) {
                                    backgroundColor = 'white'
                                    textColor = 'black'
                                }
                            } else {
                                if (value) {
                                    backgroundColor = 'white'
                                    textColor = 'black'
                                }
                            }

                            return (
                                <div className="prop-table-cell__div" style={{ background: backgroundColor, color: textColor }}>
                                    {value !== null && value !== 0 && value}
                                </div>
                            );
                        }
                    },
                    {
                        Header: 'UD Line Diff',
                        accessor: 'draftkingsOdds.udLineDiff',
                        disableSortBy: true,
                        Cell: ({ cell, row }) => {
                            const { value } = cell;

                            const isNegativeCorrelation = checkNegativeCorrelation(appFilter, cell['row']['original']['draftkingsOdds']);

                            let backgroundColor = emptyColor;
                            let textColor = emptyTextColor;

                            if (cell['row']['original']['negativeCorrelationFilter']) {
                                if (!isNegativeCorrelation && value) {
                                    backgroundColor = 'white'
                                    textColor = 'black'
                                }
                            } else {
                                if (value) {
                                    backgroundColor = 'white'
                                    textColor = 'black'
                                }
                            }

                            return (
                                <div className="prop-table-cell__div" style={{ background: backgroundColor, color: textColor }}>
                                    {value !== null && value !== 0 && value}
                                </div>
                            );
                        }
                    },
                    {
                        Header: 'Lean',
                        accessor: 'draftkingsOdds.lean',
                        disableSortBy: true,
                        Cell: ({ cell }) => {
                            const { value } = cell;

                            const isNegativeCorrelation = checkNegativeCorrelation(appFilter, cell['row']['original']['draftkingsOdds']);

                            let backgroundColor = emptyColor
                            let textColor = emptyTextColor;

                            if (cell['row']['original']['negativeCorrelationFilter']) {
                                if (!isNegativeCorrelation && value) {
                                    backgroundColor = 'white'
                                    textColor = 'black'
                                }
                            } else {
                                if (value) {
                                    backgroundColor = 'white'
                                    textColor = 'black'
                                }
                            }

                            return (
                                <div className="prop-table-cell__div" style={{ background: backgroundColor, color: textColor }}>
                                    {value}
                                </div>
                            );
                        }
                    },
                    {
                        Header: 'Fair Odds (%)',
                        accessor: 'draftkingsOdds.fairOdds',
                        Filter: NumberColumnFilter,
                        filter: minFilter,
                        Cell: ({ cell }) => {
                            const { value } = cell;

                            let amerOdds;
                            if (cell['row']['original']['draftkingsOdds']) {
                                amerOdds = cell['row']['original']['draftkingsOdds']['amerOdds'];
                            }

                            const isNegativeCorrelation = checkNegativeCorrelation(appFilter, cell['row']['original']['draftkingsOdds']);

                            let backgroundColor = emptyColor
                            let textColor = emptyTextColor;

                            if (cell['row']['original']['negativeCorrelationFilter']) {
                                if (!isNegativeCorrelation && value) {
                                    backgroundColor = color(value)
                                    textColor = 'black'
                                }
                            } else {
                                if (value) {
                                    backgroundColor = color(value)
                                    textColor = 'black'
                                }
                            }

                            return (
                                <div className="prop-table-cell__div" style={{ background: backgroundColor, color: textColor }}>
                                    {
                                        value && 
                                            <Fragment>
                                                <span>{value.toFixed(2)}</span>
                                                {
                                                    amerOdds && americanOddsFilter &&
                                                        <span>({amerOdds})</span>
                                                }
                                            </Fragment>
                                    }
                                </div>
                            );
                        }
                    },
                ]
            },
            {
                Header: 'Pinnacle',
                columns: [
                    {
                        Header: 'Line',
                        accessor: 'pinnacleOdds.lineScore',
                        disableSortBy: true,
                        Cell: ({ cell, row }) => {
                            const { value } = cell;

                            const isNegativeCorrelation = checkNegativeCorrelation(appFilter, cell['row']['original']['pinnacleOdds']);

                            let backgroundColor = emptyColor;
                            let textColor = emptyTextColor;

                            if (cell['row']['original']['negativeCorrelationFilter']) {
                                if (!isNegativeCorrelation && value) {
                                    backgroundColor = 'white'
                                    textColor = 'black'
                                }
                            } else {
                                if (value) {
                                    backgroundColor = 'white'
                                    textColor = 'black'
                                }
                            }

                            return (
                                <div className="prop-table-cell__div" style={{ background: backgroundColor, color: textColor }}>
                                    {value}
                                </div>
                            );
                        }
                    },
                    {
                        Header: 'PP Line Diff',
                        accessor: 'pinnacleOdds.ppLineDiff',
                        disableSortBy: true,
                        Cell: ({ cell, row }) => {
                            const { value } = cell;

                            const isNegativeCorrelation = checkNegativeCorrelation(appFilter, cell['row']['original']['pinnacleOdds']);

                            let backgroundColor = emptyColor;
                            let textColor = emptyTextColor;

                            if (cell['row']['original']['negativeCorrelationFilter']) {
                                if (!isNegativeCorrelation && value) {
                                    backgroundColor = 'white'
                                    textColor = 'black'
                                }
                            } else {
                                if (value) {
                                    backgroundColor = 'white'
                                    textColor = 'black'
                                }
                            }
                            return (
                                <div className="prop-table-cell__div" style={{ background: backgroundColor, color: textColor }}>
                                    {value !== null && value !== 0 && value}
                                </div>
                            );
                        }
                    },
                    {
                        Header: 'UD Line Diff',
                        accessor: 'pinnacleOdds.udLineDiff',
                        disableSortBy: true,
                        Cell: ({ cell, row }) => {
                            const { value } = cell;

                            const isNegativeCorrelation = checkNegativeCorrelation(appFilter, cell['row']['original']['pinnacleOdds']);

                            let backgroundColor = emptyColor;
                            let textColor = emptyTextColor;

                            if (cell['row']['original']['negativeCorrelationFilter']) {
                                if (!isNegativeCorrelation && value) {
                                    backgroundColor = 'white'
                                    textColor = 'black'
                                }
                            } else {
                                if (value) {
                                    backgroundColor = 'white'
                                    textColor = 'black'
                                }
                            }

                            return (
                                <div className="prop-table-cell__div" style={{ background: backgroundColor, color: textColor }}>
                                    {value !== null && value !== 0 && value}
                                </div>
                            );
                        }
                    },
                    {
                        Header: 'Lean',
                        accessor: 'pinnacleOdds.lean',
                        disableSortBy: true,
                        Cell: ({ cell }) => {
                            const { value } = cell;

                            const isNegativeCorrelation = checkNegativeCorrelation(appFilter, cell['row']['original']['pinnacleOdds']);

                            let backgroundColor = emptyColor
                            let textColor = emptyTextColor;

                            if (cell['row']['original']['negativeCorrelationFilter']) {
                                if (!isNegativeCorrelation && value) {
                                    backgroundColor = 'white'
                                    textColor = 'black'
                                }
                            } else {
                                if (value) {
                                    backgroundColor = 'white'
                                    textColor = 'black'
                                }
                            }

                            return (
                                <div className="prop-table-cell__div" style={{ background: backgroundColor, color: textColor }}>
                                    {value}
                                </div>
                            );
                        }
                    },
                    {
                        Header: 'Fair Odds (%)',
                        accessor: 'pinnacleOdds.fairOdds',
                        Filter: NumberColumnFilter,
                        filter: minFilter,
                        Cell: ({ cell }) => {
                            const { value } = cell;

                            let amerOdds;
                            if (cell['row']['original']['pinnacleOdds']) {
                                amerOdds = cell['row']['original']['pinnacleOdds']['amerOdds'];
                            }

                            const isNegativeCorrelation = checkNegativeCorrelation(appFilter, cell['row']['original']['pinnacleOdds']);

                            let backgroundColor = emptyColor
                            let textColor = emptyTextColor;

                            if (cell['row']['original']['negativeCorrelationFilter']) {
                                if (!isNegativeCorrelation && value) {
                                    backgroundColor = color(value)
                                    textColor = 'black'
                                }
                            } else {
                                if (value) {
                                    backgroundColor = color(value)
                                    textColor = 'black'
                                }
                            }

                            return (
                                <div className="prop-table-cell__div" style={{ background: backgroundColor, color: textColor }}>
                                    {
                                        value && 
                                            <Fragment>
                                                <span>{value.toFixed(2)}</span>
                                                {
                                                    amerOdds && americanOddsFilter &&
                                                        <span>({amerOdds})</span>
                                                }
                                            </Fragment>
                                    }
                                </div>
                            );
                        }
                    },
                ]
            },
            {
                Header: 'Caesars',
                columns: [
                    {
                        Header: 'Line',
                        accessor: 'caesarsOdds.lineScore',
                        disableSortBy: true,
                        Cell: ({ cell, row }) => {
                            const { value } = cell;

                            const isNegativeCorrelation = checkNegativeCorrelation(appFilter, cell['row']['original']['caesarsOdds']);

                            let backgroundColor = emptyColor;
                            let textColor = emptyTextColor;

                            if (cell['row']['original']['negativeCorrelationFilter']) {
                                if (!isNegativeCorrelation && value) {
                                    backgroundColor = 'white'
                                    textColor = 'black'
                                }
                            } else {
                                if (value) {
                                    backgroundColor = 'white'
                                    textColor = 'black'
                                }
                            }

                            return (
                                <div className="prop-table-cell__div" style={{ background: backgroundColor, color: textColor }}>
                                    {value}
                                </div>
                            );
                        }
                    },
                    {
                        Header: 'PP Line Diff',
                        accessor: 'caesarsOdds.ppLineDiff',
                        disableSortBy: true,
                        Cell: ({ cell, row }) => {
                            const { value } = cell;

                            const isNegativeCorrelation = checkNegativeCorrelation(appFilter, cell['row']['original']['caesarsOdds']);

                            let backgroundColor = emptyColor;
                            let textColor = emptyTextColor;

                            if (cell['row']['original']['negativeCorrelationFilter']) {
                                if (!isNegativeCorrelation && value) {
                                    backgroundColor = 'white'
                                    textColor = 'black'
                                }
                            } else {
                                if (value) {
                                    backgroundColor = 'white'
                                    textColor = 'black'
                                }
                            }
                            return (
                                <div className="prop-table-cell__div" style={{ background: backgroundColor, color: textColor }}>
                                    {value !== null && value !== 0 && value}
                                </div>
                            );
                        }
                    },
                    {
                        Header: 'UD Line Diff',
                        accessor: 'caesarsOdds.udLineDiff',
                        disableSortBy: true,
                        Cell: ({ cell, row }) => {
                            const { value } = cell;

                            const isNegativeCorrelation = checkNegativeCorrelation(appFilter, cell['row']['original']['caesarsOdds']);

                            let backgroundColor = emptyColor;
                            let textColor = emptyTextColor;

                            if (cell['row']['original']['negativeCorrelationFilter']) {
                                if (!isNegativeCorrelation && value) {
                                    backgroundColor = 'white'
                                    textColor = 'black'
                                }
                            } else {
                                if (value) {
                                    backgroundColor = 'white'
                                    textColor = 'black'
                                }
                            }

                            return (
                                <div className="prop-table-cell__div" style={{ background: backgroundColor, color: textColor }}>
                                    {value !== null && value !== 0 && value}
                                </div>
                            );
                        }
                    },
                    {
                        Header: 'Lean',
                        accessor: 'caesarsOdds.lean',
                        disableSortBy: true,
                        Cell: ({ cell }) => {
                            const { value } = cell;

                            const isNegativeCorrelation = checkNegativeCorrelation(appFilter, cell['row']['original']['caesarsOdds']);

                            let backgroundColor = emptyColor
                            let textColor = emptyTextColor;

                            if (cell['row']['original']['negativeCorrelationFilter']) {
                                if (!isNegativeCorrelation && value) {
                                    backgroundColor = 'white'
                                    textColor = 'black'
                                }
                            } else {
                                if (value) {
                                    backgroundColor = 'white'
                                    textColor = 'black'
                                }
                            }

                            return (
                                <div className="prop-table-cell__div" style={{ background: backgroundColor, color: textColor }}>
                                    {value}
                                </div>
                            );
                        }
                    },
                    {
                        Header: 'Fair Odds (%)',
                        accessor: 'caesarsOdds.fairOdds',
                        Filter: NumberColumnFilter,
                        filter: minFilter,
                        Cell: ({ cell }) => {
                            const { value } = cell;

                            let amerOdds;
                            if (cell['row']['original']['caesarsOdds']) {
                                amerOdds = cell['row']['original']['caesarsOdds']['amerOdds'];
                            }

                            const isNegativeCorrelation = checkNegativeCorrelation(appFilter, cell['row']['original']['caesarsOdds']);

                            let backgroundColor = emptyColor
                            let textColor = emptyTextColor;

                            if (cell['row']['original']['negativeCorrelationFilter']) {
                                if (!isNegativeCorrelation && value) {
                                    backgroundColor = color(value)
                                    textColor = 'black'
                                }
                            } else {
                                if (value) {
                                    backgroundColor = color(value)
                                    textColor = 'black'
                                }
                            }

                            return (
                                <div className="prop-table-cell__div" style={{ background: backgroundColor, color: textColor }}>
                                    {
                                        value && 
                                            <Fragment>
                                                <span>{value.toFixed(2)}</span>
                                                {
                                                    amerOdds && americanOddsFilter &&
                                                        <span>({amerOdds})</span>
                                                }
                                            </Fragment>
                                    }
                                </div>
                            );
                        }
                    },
                ]
            }
        ], [hiddenColumns]
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data: tableData,
            defaultColumn: { Filter: DefaultColumnFilter },
            initialState: { 
                pageIndex: 0, 
                pageSize: 100, 
                hiddenColumns: hiddenColumns,
                sortBy: [
                    {
                        id: 'playerName',
                        desc: false
                    },
                    {
                        id: 'statType',
                        desc: false
                    },
                ]
            },
        },
        useFilters,
        useSortBy,
        useExpanded,
        usePagination
    );

    const generateSortingIndicator = (column) => {
        return column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : '';
    };

    const onChangeInSelect = (event) => {
        setPageSize(Number(event.target.value));
    };

    const onChangeInInput = (event) => {
        const page = event.target.value ? Number(event.target.value) - 1 : 0;
        gotoPage(page);
    };

    return (
        <Fragment>
            {
                tableLoading ?
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <TailSpin
                            height="80"
                            width="80"
                            color="#4fa94d"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        />
                    </div>
                :
                <div>
                    <Row>
                        <Col xs={12}>
                            <div style={{ overflowX: 'scroll' }}>
                                <Table bordered hover {...getTableProps()} className="prop-table">
                                    <thead>
                                        {
                                            headerGroups.map(headerGroup => (
                                                <tr {...headerGroup.getHeaderGroupProps()}>
                                                    {
                                                        headerGroup.headers.map(column => {
                                                            let backgroundColor = 'white'

                                                            if (column.Header === 'FanDuel') {
                                                                backgroundColor = '#A1D2FC'
                                                            } else if (column.Header === 'DraftKings') {
                                                                backgroundColor = '#BAE356'
                                                            } else if (column.Header === 'Pinnacle') {
                                                                backgroundColor = '#F2874C'
                                                            } else if (column.Header === 'Caesars') {
                                                                backgroundColor = '#D0337A'
                                                            }

                                                            return (
                                                                <th className="prop-table-header" {...column.getHeaderProps()} style={{ backgroundColor: backgroundColor }}>
                                                                    <div {...column.getSortByToggleProps()}>
                                                                        {column.render('Header')}
                                                                        {generateSortingIndicator(column)}
                                                                    </div>
                                                                    {
                                                                        column.canFilter && <Filter column={column} />
                                                                    }
                                                                </th>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                            ))
                                        }
                                    </thead>
                                    <tbody {...getTableBodyProps()}>
                                        {
                                            page.map(row => {
                                                prepareRow(row);
                                                return (
                                                    <Fragment key={row.getRowProps().key}>
                                                        <tr>
                                                            {
                                                                row.cells.map((cell) => {
                                                                    return (
                                                                        <td {...cell.getCellProps()} className="prop-table-cell">
                                                                            {cell.render('Cell')}
                                                                        </td>
                                                                    );
                                                                })
                                                            }
                                                        </tr>
                                                  </Fragment>
                                                );
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>
                    {
                        <Fragment>
                            <div className="show-for-mobile">
                                <Row>
                                    <Col xs={12}>
                                        <div style={{ display: 'flex', justifyContent: 'center', gap: '2rem', marginTop: '1rem' }}>
                                            <div style={{ display: 'flex', gap: '0.5rem' }}>
                                                <Button
                                                    color='primary'
                                                    onClick={() => gotoPage(0)}
                                                    disabled={!canPreviousPage}
                                                >
                                                    {'<<'}
                                                </Button>
                                                <Button
                                                    color='primary'
                                                    onClick={previousPage}
                                                    disabled={!canPreviousPage}
                                                >
                                                    {'<'}
                                                </Button>
                                            </div>
                                            
                                            <div style={{ display: 'flex', gap: '0.5rem' }}>
                                                <Button color='primary' onClick={nextPage} disabled={!canNextPage}>
                                                    {'>'}
                                                </Button>
                                                <Button
                                                    color='primary'
                                                    onClick={() => gotoPage(pageCount - 1)}
                                                    disabled={!canNextPage}
                                                >
                                                    {'>>'}
                                                </Button>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <div style={{ display: 'flex', justifyContent: 'center', gap: '1rem', marginTop: '1rem' }}>
                                            <div>
                                                Page{' '}
                                                <strong>
                                                    {pageIndex + 1} of {pageOptions.length}
                                                </strong>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <div style={{ display: 'flex', justifyContent: 'center', gap: '1rem', marginTop: '1rem' }}>
                                            <Input
                                                type='number'
                                                min={1}
                                                style={{ width: 70 }}
                                                max={pageOptions.length}
                                                defaultValue={pageIndex + 1}
                                                onChange={onChangeInInput}
                                            />
                                            <CustomInput
                                                type='select'
                                                id='pageSelect'
                                                value={pageSize}
                                                onChange={onChangeInSelect}
                                            >
                                                >
                                                {
                                                    [10, 50, 100, 1000].map(pageSize => (
                                                        <option key={pageSize} value={pageSize}>
                                                            Show {pageSize}
                                                        </option>
                                                    ))
                                                }
                                            </CustomInput>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Fragment>
                    }
                    <Row className="hide-for-mobile">
                        <Col xs={12}>
                            <div style={{ display: 'flex', justifyContent: 'center', gap: '2rem', marginTop: '1rem' }}>
                                <div style={{ display: 'flex', gap: '0.5rem' }}>
                                    <Button
                                        color='primary'
                                        onClick={() => gotoPage(0)}
                                        disabled={!canPreviousPage}
                                    >
                                        {'<<'}
                                    </Button>
                                    <Button
                                        color='primary'
                                        onClick={previousPage}
                                        disabled={!canPreviousPage}
                                    >
                                        {'<'}
                                    </Button>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                    <div>
                                        Page{' '}
                                        <strong>
                                            {pageIndex + 1} of {pageOptions.length}
                                        </strong>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                    <Input
                                        type='number'
                                        min={1}
                                        style={{ width: 70 }}
                                        max={pageOptions.length}
                                        defaultValue={pageIndex + 1}
                                        onChange={onChangeInInput}
                                    />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                    <CustomInput
                                        type='select'
                                        id='pageSelect'
                                        value={pageSize}
                                        onChange={onChangeInSelect}
                                    >
                                        >
                                        {
                                            [10, 50, 100, 1000].map(pageSize => (
                                                <option key={pageSize} value={pageSize}>
                                                    Show {pageSize}
                                                </option>
                                            ))
                                        }
                                    </CustomInput>
                                </div>
                                <div style={{ display: 'flex', gap: '0.5rem' }}>
                                    <Button color='primary' onClick={nextPage} disabled={!canNextPage}>
                                        {'>'}
                                    </Button>
                                    <Button
                                        color='primary'
                                        onClick={() => gotoPage(pageCount - 1)}
                                        disabled={!canNextPage}
                                    >
                                        {'>>'}
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            }
        </Fragment>
    );
};

export default TableComponent;   
