import React from 'react';
import Layout from './components/Layout/Layout';
import {  Route, Switch } from 'react-router-dom';

import Home from './containers/MainContent/Home';
// import Props from './containers/MainContent/Props';
import Props from './containers/MainContent/PropsTemp';
import './App.scss';
import './materialdesignicons.min.css';
import './custom.css';

const App = () => {
    return (
        <Layout>
            <Switch>
                <Route path="/props" component={Props} />
                <Route path="/" component={Home} exact/>
            </Switch>
        </Layout>
    );
}

export default App;