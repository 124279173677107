import React, { Fragment } from 'react';
import { Row, Col } from 'reactstrap';

import Features from './subpages/Features';
import Counter from './subpages/Counter';
import Disclaimer from './subpages/Disclaimer';
import About from './subpages/About';

import homeImg from '../../images/home-img.webp';

const Home = () => {
    return (
        <Fragment>
            <section className="bg-home" id="home">
                <div className="bg-overlay"></div>
                <div className="home-center">
                    <div className="home-desc-center">
                        <div className="container">
                            <Row className="justify-content-center">
                                <Col lg="10">
                                    <div className="home-content text-center">
                                        <h3 className="home-title line-height_1_4 mt-5 pt-5">
                                            All The Tools You Need For PrizePicks And Underdog In One Server
                                        </h3>
                                        <p className="home-desc line-height_1_8 mt-4 f-20">
                                            Value Props Alert, Sportsbook Odds, Bump Bot, and more, completely free.
                                        </p>
                                        <div className="home-button mt-4">
                                            <a target="_blank" href="https://discord.gg/eFngWcSKg7" className="btn btn-custom btn-round mt-3">
                                                Click To Join
                                            <i className="mdi mdi-arrow-right pl-1"></i>
                                            </a>
                                        </div>
                                        <div className="home-img mt-5">
                                            <img src={homeImg} className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </section>
            <About />
            <Features />
            <Disclaimer/>
            <Counter />
        </Fragment>
    );
}

export default Home;   
