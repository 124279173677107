import React from 'react';
import { Row, Col } from 'reactstrap';

import img1 from '../../../images/features/img1.webp';
import img2 from '../../../images/features/img2.webp';
import img3 from '../../../images/features/img3.webp';
import img4 from '../../../images/features/img4.webp';
import img5 from '../../../images/features/img5.webp';
import img6 from '../../../images/features/img6.webp';
import img7 from '../../../images/features/img7.webp';

const Features = () => {
    return (
       <section className="section bg-white" id="features">
            <div className="container">
                <Row>
                    <Col lg="12">
                        <div className="title-box text-center">
                            <h3 className="title-heading">Features</h3>
                        </div>
                    </Col>
                </Row>

                <Row className="mt-4">
                    <Col lg="6">
                        <div className="features__description text-center mt-4">
                            <h5 className="mt-3">
                                Props Table
                            </h5>
                            <p className="text-muted mt-3 mb-0">
                                Lines and odds from multiple sportsbooks are automatically pulled and they are matched with active PrizePicks and Underdog props.   
                                This allows you to easily see which PrizePicks and Underdog props have favorable odds on the over/under before making your bet.
                            </p>
                            <div className="home-button mt-4">
                                <a href="/props" className="btn btn-custom btn-round mt-3">
                                    Props Page (Beta)
                                <i className="mdi mdi-arrow-right pl-1"></i>
                                </a>
                            </div>
                        </div>
                    </Col>

                    <Col lg="6">
                        <div className="features-img text-center mt-4">
                            <img src={img1} className="img-fluid" alt="" />
                        </div>
                    </Col>
                </Row>
                <Row className="mt-4"> 
                    <Col lg="6">
                        <div className="features__description  text-center mt-4">
                            <h5 className="mt-3">Filter To Only What You Want To See</h5>
                            <p className="text-muted mt-3 mb-0">
                                This server is designed to give you the most flexibility on what you want to see. You can mute sports you are not 
                                interested in, set ping notifications for the ones you are, and self-assign roles for sportsbooks you trust the most.
                            </p>
                        </div>
                    </Col>
                    <Col lg="6">
                        <div className="features-img text-center mt-4">
                            <img src={img2} className="img-fluid" alt="" />
                        </div>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col lg="6">
                        <div className="features__description  text-center mt-4">
                            <h5 className="mt-3">
                                Value Props Alert
                            </h5>
                            <p className="text-muted mt-3 mb-0">
                                If lines and odds from sportsbooks reach a defined threshold, a Value Prop alert triggers. This means that the specified 
                                PrizePicks or Underdog prop has "value" or has a positive expected value ("+EV") to hit based on how sportsbooks have set their odds. 
                                Most of the time PrizePicks and Underdog themselves follow the odds set by sportsbooks, so Value Prop alerts help you get ahead of 
                                the line bumps that may follow. The example image is a Value Prop alert sent out at 9:10, with a full 33% change 
                                to the line 26 minutes later.

                            </p>
                        </div>
                    </Col>
                    <Col lg="6">
                        <div className="features-img text-center mt-4">
                            <img src={img3} className="img-fluid" alt="" />
                        </div>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col lg="6">
                        <div className="features__description  text-center mt-4">
                            <h5 className="mt-3">
                                Added Props Bot
                            </h5>
                            <p className="text-muted mt-3 mb-0">
                                This channel notifies you on new props added to the PrizePicks and Underdog board the minute they come out. This 
                                can help you catch props that you think are too high or too low, and sometimes even props that 
                                PrizePicks and Underdog have posted on mistake. 
                            </p>    
                        </div>
                    </Col>
                    <Col lg="6">
                        <div className="features-img text-center mt-4">
                            <img src={img4} className="img-fluid" alt="" />
                        </div>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col lg="6">
                        <div className="features__description  text-center mt-4">
                            <h5 className="mt-3">
                                Bump Bot
                            </h5>
                            <p className="text-muted mt-3 mb-0">
                                This channel notifies you when PrizePicks and Underdog props' lines have changed, what the change was, and how long 
                                it took for the change to occur.
                            </p>
                        </div>
                    </Col>

                    <Col lg="6">
                        <div className="features-img text-center mt-4">
                            <img src={img5} className="img-fluid" alt="" />
                        </div>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col lg="6">
                        <div className="features__description  text-center mt-4">
                            <h5 className="mt-3">
                                Full Transparency
                            </h5>
                            <p className="text-muted mt-3 mb-0">
                                Although Value Props are not meant to tell you what to bet on (read the Disclaimers below), I want to be 
                                confident in what the bot produces. I systematically adjust the thresholds for each sport and prop type to 
                                ensure that the results meet my expections based on real data. All stats are posted and updated, including stats 
                                broken down by sport, league, date, and sportsbook. I value complete transparency and I want everyone to 
                                be aware and informed.
                            </p>
                        </div>
                    </Col>
                    <Col lg="6">
                        <div className="features-img text-center mt-4">
                            <img src={img6} className="img-fluid" alt="" />
                        </div>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col lg="6">
                        <div className="features__description  text-center">
                            <h5 className="mt-3">
                                Continual Updates And Support
                            </h5>
                            <p className="text-muted mt-3 mb-0">
                                This server is entirely developed, managed, and hosted by me (Zhaux#2827) completely for free. My goal is to provide everyone with as many tools 
                                as possible that will help you succeed on PrizePicks, Underdog and other apps. All development is done on my free time and I have open communication 
                                on what I'm working on, what needs to be improved, and to answer any questions or suggestions you may have. I am happily paying 
                                for the server and hosting costs of this server, but if you wish to support me, please check out the link below.
                            </p>
                           {/* <div className="home-button mt-4">
                                <a target="_blank" href="https://www.buymeacoffee.com/zhaux" className="btn btn-custom btn-round mt-3">
                                    Donate
                                <i className="mdi mdi-arrow-right pl-1"></i>
                                </a>
                            </div>*/}
                        </div>
                    </Col>

                    <Col lg="6">
                        <div className="features-img text-center mt-4">
                            <img src={img7} className="img-fluid" alt="" />
                        </div>
                    </Col>
                </Row>
            </div>
        </section>
    );
}

export default Features;   
