import React from 'react';
import { Row, Col } from 'reactstrap';
import CountTo from 'react-count-to';

const Counter = () => {
    return (
        <section className="section bg-cta">
            <div className="container">
                <Row id="counter">
                    <Col lg="4">
                        <div className="counter-box text-center">
                            <h2 className="counter-count text-white mt-3"> 
                                <CountTo className="counter-value" to={17000} speed={1000} />+
                            </h2>
                            <p className="mt-3 text-white-50 mb-0">Props Analyzed</p>
                        </div>
                    </Col>

                    <Col lg="4">
                        <div className="counter-box text-center">
                            <h2 className="counter-count text-white mt-3">
                                <CountTo className="counter-value" to={700} speed={1000} />+
                            </h2>
                            <p className="mt-3 text-white-50 mb-0">Value Props</p>
                        </div>
                    </Col>

                    <Col lg="4">
                        <div className="counter-box text-center">
                            <h2 className="counter-count text-white mt-3"> 
                            <CountTo className="counter-value" to={68.57} speed={1000} digits={2}/>%</h2>
                            <p className="mt-3 text-white-50 mb-0">Win Rate *</p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="counter__description">
                            <div>
                                <p>Stats as of September 26, 2022</p>
                            </div>
                            <div>
                                <p>* Win rate including pushes as wins. 62.33% win rate not including pushes.</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </section>
    );
}

export default Counter;   
