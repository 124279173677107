import React from 'react';
import { Row, Col } from 'reactstrap';

const About = () => {
    return (
        <section className="section bg-light" id="about">
            <div className="container">
                <Row>
                    <Col lg="12">
                        <div className="title-box text-center">
                            <h3 className="title-heading">
                                What is ZhauxBot?
                            </h3>
                            <p className="title-desc text-muted mt-3">
                                ZhauxBot is a <b>FREE</b> Discord server that hosts automated tools that helps improve your betting on 
                                PrizePicks and Underdog. It alerts you when props are added, updated, and when they have "value" compared to 
                                lines and odds set by the big sportsbooks. These Value Props leverage the superior models and 
                                stats used by well-established sportsbooks, and allows you to catch discrepencies of props set by 
                                PrizePicks and Underdog before their lines get changed. 
                            </p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg="12">
                        <div className="title-box text-center">
                            <p className="title-desc text-muted mt-3">
                                Currently Value Props support the following major sports, with the goal of supporting every category.
                            </p>
                        </div>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col lg="8">
                        <div className="about__sports">
                            <div>
                                <img src="https://ucarecdn.com/77328694-f455-47e7-9c78-36eb77468651/" alt="MLB" />
                                <p>MLB</p>
                            </div>
                            <div>
                                <img src="https://ucarecdn.com/fb622562-270b-4eda-b91c-40039d69744e/" alt="NBA" />
                                <p>NBA</p>
                            </div>
                            <div>
                                <img src="https://ucarecdn.com/e6a1426d-c011-455c-a1e2-b5c915175b67/" alt="NHL" />
                                <p>NHL</p>
                            </div>
                            <div>
                                <img src="https://ucarecdn.com/6004f640-f087-4ea3-8a32-17ef6c45a5aa/" alt="NFL" />
                                <p>NFL</p>
                            </div>
                            <div>
                                <img src="https://ucarecdn.com/7bd2d13c-1645-4675-bdec-53029145312f/" alt="CBB" />
                                <p>NFL</p>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg="12">
                        <div className="title-box text-center">
                            <p className="title-desc text-muted mt-3">
                                Odds and lines are collected from these sportsbooks, with more being added.
                            </p>
                        </div>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col lg="8">
                        <div className="about__sportsbooks">
                            <div>
                                <img src="https://ucarecdn.com/3f5d674f-b82d-4c12-93b4-14fd197722ce/" alt="DraftKings" />
                                <p>DraftKings</p>
                            </div>
                            <div>
                                <img src="https://ucarecdn.com/ad15b579-f76f-494b-9adf-bdd42b4682f2/" alt="FanDuel" />
                                <p>FanDuel</p>
                            </div>
                            <div>
                                <img src="https://ucarecdn.com/7b2a0866-397a-4b7e-8650-176ec3a3c14f/" alt="Pinnacle" />
                                <p>Pinnacle</p>
                            </div>
                            <div>
                                <img src="https://ucarecdn.com/1dc8ef7c-b09e-44f5-8ecd-e406a0bfb445/" alt="Caesars" />
                                <p>Caesars</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </section>
    );
}

export default About;   
