import React from 'react';

import Topbar from './Topbar';

const Layout = ({ children }) => {
	return (
		<main>
	  		<div className="wrapper">
				<Topbar />
				{children}
		  	</div>
		</main>
	);
}

export default Layout;
