import React from 'react';
import { Row, Col } from 'reactstrap';

const Disclaimer = () => {
    return (
        <section className="section bg-light" id="disclaimers">
            <div className="container">
                <Row>
                    <Col lg="12">
                        <div className="title-box text-center">
                            <h3 className="title-heading">
                                Disclaimers
                            </h3>
                            <p className="title-desc text-muted mt-3">
                                ZhauxBot is NOT meant to tell you on what to bet on. This server is purely meant to provide 
                                tools that can help you make your own decisions. I make an effort to be as open as possible to 
                                what sportsbooks I use, how I calculate the thresholds, and the Value Props hit record. Value Props 
                                are entirely based on lines and odds published by sportsbooks at a given time, and at the end of 
                                the day this is sports betting. Players can miss, odds can change, sportsbooks can be wrong, and a 
                                70% hit probability will still lose 3 out of 10 times. Do your own research, do not blindly tail, and understand 
                                the risks.
                            </p>
                        </div>
                    </Col>
                </Row>
            </div>
        </section>
    );
}

export default Disclaimer;   
